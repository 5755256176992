import { getReference } from '@atc/bonnet-reference';
import { getSyndicatedMakesFromState, removeMakeModels } from '../utilities';

export default function getMakeList(state) {

    return async (ctx) => {
        const makeListCondition = ctx.data && ctx.data.brand; // get makelist from researchable api for both KBB and Autotrader
        if (makeListCondition) {
            const { payload: makeCodes } = await getReference('makeCode');
            ctx.data.makeCodes = removeMakeModels(makeCodes);
            ctx.data.makeList = await getSyndicatedMakesFromState(state);
            if (ctx.query && ctx.query.makeCode) {
                if (makeCodes.length > 0) {
                    const fmake = makeCodes.find((m) => m.code?.toLowerCase() === ctx.query.makeCode?.toLowerCase());
                    ctx.query.make = fmake && fmake.name ? fmake.name : '';
                }
            }
        }
    };
}
