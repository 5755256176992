import { useBrand } from 'reaxl-brand';
import { useFeature } from 'reaxl-features';
import { useSelector } from 'react-redux';
import analytics from '.';
import useDataLayer from './useDataLayer';
import { kbbBrand } from '../constants/index';

function useAnalyticsProps({ disablePostMessage = false } = {}) {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const [, { disableGtm, disableGtmEvents }] = useFeature('google_analytics');
    const dataLayer = useDataLayer();
    const dataIsland = useSelector((state) => state?.birf?.pageData);

    const analyticsProps = {
        value: {
            ...analytics,
        },
        dataLayer: () => ({ ...dataLayer }),
        getDataIsland: () => ({ ...dataIsland }),
        option: {
            disableGTM: disableGtm,
            disableGTMEvents: disableGtmEvents,
            disablePostMessage,
            ...(isKbb && {
                isOptimizelyEdge: true,
                populateChannelManager: false,
            }),
        },
    };

    return analyticsProps;
}

export default useAnalyticsProps;
