import { fetchJSON } from '@bonnet/next/fetch';

const dealerLotCircuitBreakerOptions = {
    timeout: 3000,
    fallback: {},
    id: 'lsc_dealerlot_single',
};

export default (options) => fetchJSON('/rest/lsc/dealerlot/single', {
    dealerLotCircuitBreakerOptions,
    ...options,
});
