const createDdpLinkForKbb = (isKbb, href, isPayingDealer, isViewCarsButton) => {
    const joinChar = href?.includes('?') ? '&' : '?';
    let salesTabParam = '';
    if (isViewCarsButton && isKbb) {
        salesTabParam = 'dealerdetailstabs=sales#sales';
    } else if (!isPayingDealer && isKbb) {
        salesTabParam = 'dealerdetailstabs=reviews#reviews';
    } else if (isPayingDealer && isKbb) {
        salesTabParam = 'dealerdetailstabs=service#service';
    } else {
        salesTabParam = 'dealerdetailstabs=sales#sales';
    }
    const ret = `${href}${joinChar}${salesTabParam}`;
    return ret;
};

export default createDdpLinkForKbb;
