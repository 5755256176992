import { pageNames } from '@atc/bonnet-paths';

const pattern = [
    /(dealers\/new(?:\/|\?|$))/im,
    /(dealers\/used(?:\/|\?|$))/im,
    /(dealers\/third-party-certified(?:\/|\?|$))/im,
    /(dealers\/all(?:\/|\?|$))/im,
    /(dealers\/certified(?:\/|\?|$))/im,
];

const isNoIndex = (pageName, pathname) => {
    if (pageName === pageNames.DEALER_SEARCH && pathname) {
        return pattern.some((reg) => reg.test(pathname));
    }
    return false;
};

export default isNoIndex;
