import { getMarketFromCityState } from '@atc/bonnet-reference';

const getLocationFromSlug = async (slug = []) => {
    let location = null;

    for (const segment of slug) {
        const splitSegments = segment.split('-');

        if (splitSegments.length >= 2) {
            const state = splitSegments.splice(splitSegments.length - 1)[0];
            const city = splitSegments.join(' ');
            // eslint-disable-next-line no-await-in-loop
            const { success, payload } = await getMarketFromCityState({ city, state });

            if (success && payload) {
                location = {
                    city: payload.city,
                    state: payload.state,
                    zip: payload.zip,
                };
            }
        }
    }

    return location;
};

export default function withCtxCommonQuery() {
    return async (ctx) => {
        if (!ctx.query.zip) {
            const location = await getLocationFromSlug(ctx.query.slug);
            if (location) {
                ctx.query = {
                    ...ctx.query,
                    ...location,
                };
            }
        }
    };
}
