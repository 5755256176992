import { pageNames } from '@atc/bonnet-paths';
import _get from 'lodash/get';
import { kbbBrand, dealerlistQuery, dealerDetailQuery } from '../constants';
import { getPath, getCityZipCode, filterDealerQuery } from '../utilities';

const getDealeName = (brand, owner) => {
    const { name: dealerName, franchiseName } = owner;
    return (brand === kbbBrand ? franchiseName : dealerName);
};

export default function processOwnerResults() {
    return async (ctx) => {
        let data = {
            urls: {},
            activeResults: [],
        };

        if (ctx.data.owners) {
            data = await ctx.data.owners.reduce(async (previous, owner) => {
                const acc = await previous;

                // Build Dealer Details urls for each result
                const { dealerId, hasDealerDetail } = owner;
                const ownerLocation = _get(owner, 'location.address', {});
                const { city, state } = ownerLocation;
                const cityCenterZip = await getCityZipCode(ownerLocation);
                const isKbb = (ctx.data.brand === kbbBrand);

                // remove unwanted parameters from the existing query string
                // eslint-disable-next-line no-unused-vars
                const query = filterDealerQuery(ctx.query, dealerlistQuery.concat(dealerDetailQuery));

                // Build a path for the dealer details page of this owner
                if (hasDealerDetail && dealerId) {
                    if (isKbb) {
                        delete query.make;
                    }
                    const path = await getPath(pageNames.DEALER_DETAILS, {
                        ...query,
                        dealerId,
                        dealerName: getDealeName(ctx.data.brand, owner),
                        city,
                        state,
                        zip: cityCenterZip,
                    }, { brand: ctx.data.brand });

                    acc.urls[owner.id] = path;
                }

                // push owner to active results
                acc.activeResults.push(owner.id);

                return acc;
            }, Promise.resolve(data));
        }

        Object.assign(ctx.data, data);
    };
}
